<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Print queue</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Print queue
                </div>
              </div>
              <div class="breadcrumb-right"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>

                <table class="table" v-if="!loading">
                    <thead>
                        <tr class="text-left">
                            <th class="px-3">Job detail</th>
                            <th class="px-3">Type</th>
                            <th class="px-3">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in printJobs" :key="index">
                            <td class="px-2">
                                <div v-if="item.candidate_full_name">
                                    <strong>{{ item.candidate_full_name }}</strong> ({{ item.unique_id }})
                                </div>
                                <div>
                                    <a @click="gotoNotification(item.user_notification_id)"> {{ item.user_notification_title }} </a> 
                                    <br>
                                    <small>{{ item.user_notification_date }}</small>
                                </div>
                                <div class="my-2">
                                    <span class="badge" :class="item.is_file_ready ? 'badge-primary' : 'badge-warning'"> 
                                        {{item.is_file_ready ? 'File ready' : 'File not ready'}}
                                    </span>
                                </div>
                            </td>
                            <td class="px-2">
                                <span class="uppercase">
                                {{ item.type }}
                                </span>
                            </td>
                            <td class="px-2">
                                <template>
                                    <b-dropdown
                                        size="sm"
                                        variant="link"
                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                        no-caret
                                        right
                                        no-flip
                                    >
                                    <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                    </template>
                                    <div class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div" class="navi-item">
                                            <a class="navi-link" @click.prevent="removeFromList(item.id)">
                                                <span class="navi-icon">
                                                    <i class="fas fa-trash"></i>
                                                </span>
                                                <span class="navi-text">Remove from print queue</span>
                                            </a>
                                        </b-dropdown-text>
                                    </div>
                                    </b-dropdown>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="printJobs.length == 0">
                            <td colspan="3" class="text-center">
                                No items found
                            </td>
                        </tr>
                    </tbody>
                </table>
                <b-pagination
                  v-if="printJobs.length > 0"
                  class="pull-right mt-7"
                  @input="getIncompletePrintJobs"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import PrintJobService from "@/services/print-job/PrintJobService";

const printJob = new PrintJobService();

export default {
    data(){
        return {
            loading: false,
            printJobs: [],
            total: null,
            perPage: null,
            page: null,
        }
    },
    methods:{
        getIncompletePrintJobs(){
            this.loading = true;
            printJob
            .getIncompletePrintJobs({}, this.page)
            .then((res) => {
                this.printJobs = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(() => {
                this.loading = false;
            })
        },
        removeFromList(id){
            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        printJob
                        .removeFromNotification(id)
                        .then((response) => {
                            this.getIncompletePrintJobs();
                            this.$snotify.success("Removed from print queue");
                        })
                        .catch((err) => {
                        });
                    }
                },
            });
        },
        gotoNotification(userNotificationId){
            this.$router.push(
                {
                    name: 'print-job-queue',
                    params: {
                        userNotificationId: userNotificationId
                    }
                }
            )
        }
    },
    mounted(){
        this.getIncompletePrintJobs();
    }
};
</script>

<style scoped>
    .uppercase{
        text-transform: uppercase;
    }
</style>